import React from 'react'
import './Product.scss'
import axios from 'axios'
import config from 'config';
import { store } from 'Redux/store';
import actions from 'Redux/actions';

import Checkout from 'Components/Checkout'
import Card from 'Components/Checkout/Card'

import './SingleProduct.scss'
import Bookmark from 'Components/Bookmark'
import Thankyou from 'Components/Thankyou'

import { Elements } from 'react-stripe-elements'

import { Auth } from 'Services/Auth'

import Product from './Product'

import Slider from "react-slick";
import Loader from 'Components/Loader'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class ImageSlider extends React.Component {


    componentDidUpdate() { 
        
    }

    getImagesWithOptions() {
        let images = this.props.images;
        const { variants, options } = this.props;

        for (let i = 0; i < variants.length; i++) {
            const imageIndex = images.findIndex(function (e) {
                return parseFloat(variants[i].image_id) === parseFloat(e.id)
            });
            if (imageIndex > -1) {
                images[imageIndex].optionString =
                    (variants[i].option1 ? options[0].name + ': ' + variants[i].option1 : '') +
                    (variants[i].option2 ? ', ' + options[1].name + ': ' + variants[i].option2 : '') +
                    (variants[i].option3 ? ', ' + options[2].name + ': ' + variants[i].option3 : '')
            }

        }

        return images
    }



    render() {

        const images = this.getImagesWithOptions()

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <Slider onInit={this.setSliderHeight} ref={c => (this.slider = c)} {...settings}>
                {images.map((value, i) => (
                    <div key={i}>
                        {value.optionString && <div className='image-options'>{value.optionString}</div>}
                        <img width="100%"  alt="" src={value.src} /></div>
                ))}
            </Slider>
        );
    }
}


class SingleProduct extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            data: {},
            description: false,
            black: false,
            section: false,
            preSelected: '',
            selectedVariant: {},
            related: {},
            buttonDisabled: false,
            payment_intent: {},
            deltaPosition: {
                x: 0, y: 0
            },
            scroll: 0
        }

        this.loadProduct = this.loadProduct.bind(this)
        this.handleSection = this.handleSection.bind(this)
        this.blackClick = this.blackClick.bind(this)
        this.openPaymentSection = this.openPaymentSection.bind(this)
        this.updatePreSelected = this.updatePreSelected.bind(this)
        this.handleOptions = this.handleOptions.bind(this)
        this.findVariant = this.findVariant.bind(this)
        this.updatePaymentIntent = this.updatePaymentIntent.bind(this)

    }

    componentWillMount() {
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate()
        })

        this.loadProduct()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }


    componentDidUpdate(prevProps) {

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ data: {} });
            this.loadProduct();
            window.scrollTo(0, 0)
        }

        const { payment } = store.getState();

        if (payment.thankyou === true && this.state.section !== 'thankyou') {

            this.setState({
                section: 'thankyou',
                black: true
            })

            document.body.classList.add('overlay-open')
        }

        if (payment.thankyou === false && this.state.section === 'thankyou') {
            this.setState({
                section: false,
                black: false
            })
        }

    }

    loadProduct() {
        let component = this
        axios.get(config.API_PRODUCT_URL + this.props.match.params.id, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({
                    data: response.data.data,
                    related: response.data.related,
                    selectedVariant: response.data.data.variants[0],
                    option1: response.data.data.variants[0].option1,
                    option2: response.data.data.variants[0].option2,
                    option3: response.data.data.variants[0].option3
                })

                store.dispatch(actions.addVisited(response.data.data))
            })
            .catch(function(e){
                console.log(e.response)
                if(e.response.status === 404)
                component.setState({notFound:true})
            });
    }

    handleSection(e) {
        const section = e.target.getAttribute("rel")
        this.setState({
            section: section,
            black: true,
            scroll: window.scrollY
        })
        document.getElementById('body').style.top = "-"+window.scrollY+"px";
        document.body.classList.add('overlay-open')
    }

    openPaymentSection() {
        let component = this;
        this.setState({
            section: 'payment',
            black: true
        })

        axios.post(config.API_PAYMENT_INTENT_URL, {
            amount: this.state.selectedVariant.price * 100,
            line_items: [this.state.selectedVariant.id]

        }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({ payment_intent: response.data })
                console.log(response.data)
            });
    }

    updatePaymentIntent() {

        if (!this.state.payment_intent.id) return;

        let component = this

        this.setState({ buttonDisabled: true })

        axios.post(config.API_PAYMENT_INTENT_UPDATE_URL, {
            amount: this.state.selectedVariant.price * 100,
            payment_intent_id: this.state.payment_intent.id
        }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({ payment_intent: response.data, buttonDisabled: false })
            });
    }

    blackClick() {

        if (this.state.section === "thankyou")
            store.dispatch(actions.hideThankYou())

        this.setState({
            section: false,
            black: false
        })

        document.body.classList.remove('overlay-open')
        window.scrollTo(0, this.state.scroll);
    }

    updatePreSelected(e) {
        this.setState({
            preSelected: e.target.value,
        })
    }

    handleOptions(e) {
        this.setState({
            [e.target.getAttribute('data-option')]: e.target.value,
        }, function () { this.findVariant() })
    }

    findVariant() {
        const { data, selectedVariant } = this.state
        const self = this

        var find = data.variants.filter(function (result) {
            return result.option1 === self.state.option1 && result.option2 === self.state.option2 && result.option3 === self.state.option3;
        });

        const current_price = selectedVariant.price

        this.setState({
            selectedVariant: find[0],
        }, function () {
            if (self.state.payment_intent && current_price !== find[0].price) { this.updatePaymentIntent() }
        })
    }



    render() {

        const { data, related } = this.state
        const { payment } = store.getState()



        return (
            <div>
                <div onClick={this.blackClick} className={this.state.black ? "black visible" : "black"}></div>


                <div className="product-container">

                    {!data.id && !this.state.notFound &&
                        <Loader />
                    }

                    {this.state.notFound && <span>404 - not found</span>}

                    {data && data.id && <div>
                        <div className="discount">-{Math.round(data.default_variant.discount)}%</div>
                        {data.images && data.images.length &&
                            <ImageSlider variants={data.variants} options={data.options} images={data.images} />
                        }

                        <div className="product-inner options">
                            <Bookmark id_product={data.id} />

                            <h1>{data.title}</h1>

                            <div className="option shipping">
                                <h2>Standard Shipping</h2>
                                <span>free</span><br />
                            </div>

                            {data.options.map((value, i) =>
                                value.name !== "Title" &&
                                <div key={i} className="option width50">
                                    <h2>{value.name}</h2>
                                    <select data-option={"option" + (i + 1)} onChange={this.handleOptions}>
                                        {value.values.map((value, i) => (
                                            <option key={i}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <div className="clearfix"></div>
                        </div>

                        <div className="thick-hr"></div>

                        <div className="product-inner">

                            <div className="description option">
                                <p onClick={this.handleSection} rel="description" className="selected">Description</p>

                                <div
                                    className={this.state.section === "description" ? "option-full full-description visible" : "option-full full-description"}>
                                     <button className="close-option" onClick={this.blackClick}>Close</button>
                                    <p className="center"><span>Description</span></p>

                                    <div className="hr"></div>

                                    <div className="desc">
                                        {<div dangerouslySetInnerHTML={{ __html: data.body_html.replace(/<img[^>]*>/g, "").replace(/style="[^"]*"/g, "") }} className="padding"></div>}
                                    </div>
                                </div>

                            </div>

                            <div className="option payments">
                                <h2>Payment details</h2>
                                <select onChange={this.updatePreSelected}>
                                    {payment.methods && payment.methods.apppay &&
                                        <option rel="payment" className="selected" value="apppay">Apple Pay / Google Pay</option>}
                                    {payment.methods && payment.methods.card &&
                                        <option value="card" rel="payment" className="">Pay by credit card</option>}
                                </select>

                                <div className={this.state.section === "payment" ? "option-full full-description visible" : "option-full full-description"}>
                                    <button className="close-option" onClick={this.blackClick}>Close</button>

                                    <p className="center">Payment</p>

                                    <div className="hr"></div>

                                    <div>
                                        {this.state.selectedVariant.id && <Elements>
                                            <Card product={data} variant={this.state.selectedVariant} payment_intent={this.state.payment_intent} />
                                        </Elements>}
                                    </div>

                                </div>

                                <div className={this.state.section === "thankyou" ? "thankyou option-full full-description visible" : "thankyou option-full full-description"}>
                                <button className="close-option" onClick={this.blackClick}>Close</button>
                                    <p className="center"><span>&nbsp;</span></p>


                                    <div className="desc">
                                        <Thankyou />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="thick-hr before-products"></div>

                        <div className="product-inner">

                            <div className="products">
                                <div className="inproducts related">
                                    {related && related.length && related.map((value, index) => {
                                        return <Product className="col-6" key={index} data={value} />
                                    })}
                                </div>
                            </div>

                        </div>
                        
                        {

                            <div className="pay-container">
                            {
                            this.state.selectedVariant.inventory_quantity < 1 &&
                            <div className="error-note">
                                This variant of the product is unavailable
                            </div>
                        }
                                <div className="pay">
                                    <div className="prices">
                                        <span className="price">${this.state.selectedVariant.price}</span>
                                        {this.state.selectedVariant.compare_at_price && <span className="stroke">${this.state.selectedVariant.compare_at_price} </span>}
                                    </div>

                                    <Elements>
                                        <Checkout key={this.state.selectedVariant.price}
                                            product={data}
                                            selectedVariant={this.state.selectedVariant}
                                            onCardSelected={this.openPaymentSection}
                                            preSelected={this.state.preSelected} />
                                    </Elements>

                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        }
                    </div>}

                </div>

            </div>



        )
    }
}

export default SingleProduct;