import React from 'react'
import axios from 'axios'
import config from 'config';

import { Auth } from 'Services/Auth'
import { Redirect } from 'react-router-dom'
import { store } from 'Redux/store'

import actions from 'Redux/actions';

class VerifyUpdatePhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseReceived: false,
      error: false,
    }
  }

  componentDidMount() {
    const { token } = (this.props.match.params)
    const component = this

    axios.post(config.API_PHONE_UPDATE_CONFIRM_URL, {
      token: token,
    }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        component.setState({ responseReceived: true })
        store.dispatch(actions.setUser(response.data))
        component.forceUpdate()
      })
      .catch(function(error){ console.log(error); component.setState({ error: error.response.data.errors }) });
  }

  render() {
    const { responseReceived } = this.state

    if (this.state.error)
      return (
         <div>

            <div className="product-container">
              Token mismatch.
            </div>

         </div>
      );
    else
      return (
        responseReceived && <Redirect to='/profile' />
      );
  }
}

export default VerifyUpdatePhone;