import React from 'react'
import { store } from 'Redux/store'
import actions from 'Redux/actions'

import Product from 'Components/Product/Product'
import './index.scss'
import { withRouter, Link } from 'react-router-dom'

class Thankyou extends React.Component
{
  constructor(props)
  {
    super( props );
    this.state = {
    }
  }

  componentDidMount()
  {
    this.unsubscribe = store.subscribe(() => {
        this.forceUpdate()
    })

    
  }

  componentWillUnmount()
  {
    store.dispatch(actions.hideThankYou())
    this.unsubscribe()
  }

  render()
  {
   const { order, products } = store.getState()
    return (
            
            <div className="page-thankyou">

             
              <div className="hr"></div>

                <div className="checkmark">
                  <div className="circle">
                    <div className="check"></div>
                  </div>  
                </div>
  
                <div className="message">
                    <h1>Order {order.last.name} confirmed</h1>

                    <p>Thank you for shopping at WOW. We will send you a shipping confirmation email once your order ships.</p>
                    <Link to="/" className="blue">Continue shopping</Link>
                </div>

                <div className="products thankyou-products">
                    { products.popular && products.popular.products.map((value, index) => {
                     return <Product template="simple" className="col-4" key={index} data={value} />
                    }) }
                </div>

            </div>
      )
  }
}

export default withRouter(Thankyou);