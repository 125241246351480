import React from 'react'

class BookmarkOff extends React.Component
{
render()
{
    return (

<svg width="23px" height="26px" viewBox="0 0 23 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Product" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Product---default---single-option" transform="translate(-332.000000, -442.000000)">
            <g id="icon/tab-bar/bookmark-off" transform="translate(328.000000, 439.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                <path d="M4.85,28.3261331 L4.85,4 L5,3.85 L26,3.85 L26.15,4 L26.15,28.3261331 L15.5,19.1975617 L4.85,28.3261331 Z M6.15,5.15 L6.15,25.4996463 L15.4023813,17.5690337 L15.5976187,17.5690337 L24.85,25.4996463 L24.85,5.15 L6.15,5.15 Z" fill="#0e0e0e"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default BookmarkOff;