import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import config from 'config';
import { store } from 'Redux/store';
import actions from 'Redux/actions'

import { Auth } from 'Services/Auth'
import './../index.scss'

import Gravatar from 'react-gravatar'



class Profile extends React.Component {
    constructor(props) {

        const { auth } = store.getState()

        super(props);
        this.state = {
            responseReceived: false,
            user: { name: auth.user.name },
        }


        this.handleChange = this.handleChange.bind(this)
        this.save = this.save.bind(this)
    }



    handleChange(e) {
        this.setState({
            user:{
            ...this.state.user,
            [e.target.name]: e.target.value
            }
        })
    }

    save() {
        const self = this
        axios.post(config.API_PROFILE_UPDATE_URL, { name: this.state.user.name }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                store.dispatch(actions.setUser(response.data))
                self.setState({responseReceived: true});
                setTimeout(function(){
                    document.getElementById('alert').style.display='none';
                },5000);
            }).catch(function(error) { console.log(error.response.data) });
    }


    render() {

        const { auth } = store.getState()

        if(!auth.auth)
            return <Redirect to="/" />

        return <div >
            <div className="settings-container">
                <div className="row settings-header">

                    <Link to="/" className="col-3">Cancel</Link>
                    <div className="col-6"><h1>Edit Profile</h1></div>
                    <div className="col-3"><button onClick={this.save} className="back save">Save</button></div>
                </div>

                    <div>
                        { this.state.responseReceived && <div id="alert" className="alert alert-success">Your data is updated</div> }
    
                        <div className="row profile-container">
                           <div className="photo">
                           <Gravatar email={auth.user.email} size={100} rating="pg" default="monsterid" className="CustomAvatar-image" />
                        </div>

                            <form>
                                <ul className="profile-ul">
                                    <li>
                                       <h2>Full name</h2>
                                        <input defaultValue={auth.user.name} onChange={this.handleChange} type="text" name="name" className="" /><div class="clearfix"></div>
                                    </li>
                                    <li>
                                        <h2>Email</h2>
                                        <Link to="/profile/email">{auth.user.email}</Link><div class="clearfix"></div>
                                    </li>
                                    <li>
                                       <h2>Phone</h2>
                                       <Link to="/profile/phone">{auth.user.phone_number}</Link><div class="clearfix"></div>
                                    </li>

                                </ul>
                            </form>

                        </div>
      

                    </div>

                </div>


            </div>

    }
}

export default Profile;