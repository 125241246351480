import types from 'Redux/types';

const INITIAL_STATE = {
 feed: false
}

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {

    case types.FEED_TYPE_SAVE: {
      return {
        ...state,
        feedType: action.payload
      }
    }
 
    default: return state;
  }
}

export default settingsReducer;