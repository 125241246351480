import React from 'react';
import ProductList from 'Components/Product/ProductList';
import Overlay from 'Components/Overlay';
import './App.scss'
import { store } from 'Redux/store';
import { Redirect } from 'react-router-dom';



export class App extends React.Component
{
  constructor(props)
  {  
    super(props)
    this.updateClasses = this.updateClasses.bind(this)
  }

  componentDidUpdate()
  {
    this.updateClasses()
  }

  updateClasses()
  {
    const {auth} = store.getState();

    if(!auth.user || !auth.user.email)
    {
      document.body.classList.add("hidden");
      document.body.classList.add('overlay-open');
    }
    else
    {
      document.body.classList.remove("hidden");
      document.body.classList.remove('overlay-open');
    }
  }


  componentWillMount()
  {

    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });
  }



  componentWillUnmount()
  {
    this.unsubscribe();
  }

  render() {
    const {auth} = store.getState();

    if(this.props.type==="login" && auth.auth)
      return <Redirect to="/" />

    return (
      <div>
        { !auth.auth && <Overlay type={this.props.type ? this.props.type : 'register'} /> }
        { auth.auth && auth.user && auth.user.id && !auth.user.email && <Overlay type="setup" /> }
        {this.props.type === "reset" && !auth.auth && <Overlay type="reset" /> }
        <ProductList type={this.props.match} />
      </div>
    );
  }
}



export default App;
