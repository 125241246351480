import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter, Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
//import { store } from '../../../Redux/store';
//import actions from 'Redux/actions'
import Loader from 'Components/Loader'

class Forgotten extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      email: '',
      phone_number: '',
      responseReceived: false,
      errors: {},
      exists: false,
      loading: false
    }

    this.sendResetLink = this.sendResetLink.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  sendResetLink() {
    const component = this
    const phone = this.state.phone_number.replace(" ", "")

    axios.post(config.API_FORGOTTEN_URL, {
      phone_number: phone
    })
      .then(function (response) {
        component.setState({responseReceived: true, loading: false})
      })
      .catch(function (error) {
        component.setState({ errors:  error.response.data.errors, loading: false  })

      });
  }

  update(number, country) {
    this.setState({
      ...this.state,
      phone_number: number,
    });
  }

componentDidMount()
{
  const { state } = this.props.location
  if(state)
    this.setState({ errors: { ...this.state.errors, phone_number: state.error } })
}

  submit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.validator.message('phone_number', this.state.phone_number, 'required|min:9')
 
    if (this.validator.allValid()) {
      this.sendResetLink();
    } else {
      this.setState({ errors: this.validator.getErrorMessages(), loading: false });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors } = this.state
    

    return (
      <div>
        {responseReceived === false &&
          <form onSubmit={this.submit}>
            <h1>Forgot Your Password?</h1>
            <p className="prompt">Enter your phone number and we’ll send you a link to reset your password.</p>

            <div className="forgotten">
            
            <div className="form-group signup-error">
              <div className={errors.phone_number ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.phone_number}</div>
            </div>
            
            <IntlTelInput
              containerClassName="intl-tel-input form-group signup"
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                this.update(number, countryData);
              }}
              onSelectFlag={(status, value, countryData, number, id) => {
                this.update(number, countryData);
              }}
              inputClassName={"form-control " + (errors.phone_number && 'is-invalid')}
              fieldName="phone_number"
              fieldId="phone"
              formatOnInit="false"
            />
      
            <button className={this.state.loading ? "loading btn-primary btn" : "btn-primary btn"}>
              {this.state.loading ? <Loader /> : 'Continue'}
            </button> 
            <p className="center">
            <Link to="/login" className="forgot">Log in</Link>
          </p>
               </div>
          </form>}
        {
          responseReceived && <span>Please check your mobile phone and enter the url which is sent to you.</span>
        }
      </div>
    );
  }
}

export default withRouter(Forgotten);