const PRODUCTS_ADD = 'PRODUCTS_ADD'
const PRODUCTS_INITIAL_ADD = 'PRODUCTS_INITIAL_ADD'
const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
const PRODUCTS_LOADED = 'PRODUCTS_LOADED'

const AUTHENTICATE = 'AUTHENTICATE'
const LOGOUT = 'LOGOUT'
const USER_SET = 'USER_SET'
const PHONE_NUMBER_SAVE = 'PHONE_NUMBER_SAVE'

const BOOKMARKS_SET = 'BOOKMARKS_SET'
const CATEGORIES_SAVE = 'CATEGORIES_SAVE'
const SEARCH_HINTS_SAVE = 'SEARCH_HINTS_SAVE'

const PAYMENT_METHODS_UPDATE = 'PAYMENT_METHODS_UPDATE'
const PRODUCTS_POPULAR_ADD = 'PRODUCTS_POPULAR_ADD'
const LAST_ORDER_SAVE = 'LAST_ORDER_SAVE'

const ORDERS_ADD = 'ORDERS_ADD'
const SEARCH_OPEN = 'SEARCH_OPEN'
const SEARCH_CLOSE = 'SEARCH_CLOSE'
const PRODUCTS_CLEAN = 'PRODUCTS_CLEAN'
const DISPLAY_THANK_YOU = 'DISPLAY_THANK_YOU'
const HIDE_THANK_YOU = 'HIDE_THANK_YOU'
const SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION'
const FEED_TYPE_SAVE = 'FEED_TYPE_SAVE'
const VISITED_ADD = 'VISITED_ADD'


export default {
    PRODUCTS_ADD,
    PRODUCTS_INITIAL_ADD,
    PRODUCTS_LOADING,
    PRODUCTS_LOADED,
    LOGOUT,
    AUTHENTICATE,
    USER_SET,
    BOOKMARKS_SET,
    CATEGORIES_SAVE,
    SEARCH_HINTS_SAVE,
    PAYMENT_METHODS_UPDATE,
    PRODUCTS_POPULAR_ADD,
    LAST_ORDER_SAVE,
    ORDERS_ADD,
    PHONE_NUMBER_SAVE,
    SEARCH_OPEN,
    SEARCH_CLOSE,
    PRODUCTS_CLEAN,
    DISPLAY_THANK_YOU,
    HIDE_THANK_YOU,
    SAVE_SCROLL_POSITION,
    FEED_TYPE_SAVE,
    VISITED_ADD
}