import React from 'react';
import {store} from 'Redux/store'
import {Link, withRouter} from 'react-router-dom'
import './index.scss'
import Loader from 'Components/Loader'
import Product from 'Components/Product/Product';

class Categories extends React.Component
{

    componentWillMount()
    {
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate()
        });
    }

    componentWillUnmount()
    {
        this.unsubscribe();
    }

    render()
    {
        let { categories, products } = store.getState();
        categories = categories.data

       // console.log(categories)

        return (
        <div className="page-categories">
                <div className="categories">
                <div className="title">All Categories</div>
                {!Array.isArray(categories)  &&
                    <Loader />
                }
                 {Array.isArray(categories) &&
                    <ul>
                    {categories && categories.length && categories.map((value,i) => <li key={i} >
                    <Link to={'/category/' + value.handle} >
                    
                    {value.image && <div className="image"><img alt="category" width="200" src={value.image} /></div>}  
                    <span>{value.title}</span>
                    
                    </Link>
                    </li> )}
                    </ul>
                 }
                    
                    <div className="clear"></div>
                </div>
                <div className="popular">
                        {products.popular  && <h2>Popular</h2>}

                        <div className="products">
                        {products.popular && products.popular.products && products.popular.products.map((value, index) => {
                            return <Product template="simple" className="col-4" key={index} data={value} />
                        })}
                        </div>

                    </div>
        </div>    
        )
    }
}

export default withRouter(Categories)