import React from 'react'
import axios from 'axios'
import config from 'config';
import actions from 'Redux/actions'
import { store } from 'Redux/store'
import { Auth } from 'Services/Auth'
import SimpleReactValidator from 'simple-react-validator'

class Setup extends React.Component
{
  constructor(props)
  {
    super(props)

    this.state = {
      name: '',
      email: '',
      errors: {},
    }

    this.setup = this.setup.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();    
  }

  setup()
  {
    const component = this

    const first_name = this.state.name.split(" ")[0]
    const last_name = this.state.name.split(' ').slice(1).join(' ');

    axios.post(config.API_SETUP_URL, {
      email: this.state.email,
      first_name: first_name,
      last_name: last_name,
    },
      { headers: { 'Authorization': "bearer " + Auth.getToken() } }
    )
    .then(function (response) {
      
      if(response.data)
      {
        store.dispatch(actions.setUser(response.data))
      }
    })
    .catch(function(error) {
      if(error.response.data.errors)
      {
        component.setState({errors: error.response.data.errors})
      }
    });;
  }

  update(e)
  {
     const { name, value } = e.target;

      this.setState({
        ...this.state,
        [name]: value,
      });
  }

  submit(e)
  {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setup();
    } else {
      this.setState({errors : this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

    render()
    {
      const {errors} = this.state
      
      this.validator.message('name', this.state.name, 'required|string|min:2')
      this.validator.message('email', this.state.email, 'required|email')

      return (
        <div>

           <form onSubmit={this.submit} className="create">
            <h1>Create An Account</h1>
            <p className="prompt">Just a couple of details and<br/>you&apos;re good to go!</p>

            <div className="form-group">
              <input className={"form-control " + (errors.name && 'is-invalid')} id="name" type="text" name="name" placeholder="Full Name" onChange={this.update} />
              <div className="invalid-feedback">{errors.name}</div>
            </div>

            <div className="form-group">
              <input className={"form-control " + (errors.email && 'is-invalid')} id="email" type="text" name="email" placeholder="Email" onChange={this.update} />
              <div className="invalid-feedback">{errors.email}</div>
            </div>
                
                <button className="btn-primary btn">
                Create An Account
                </button>
           </form> 
         
         </div>
      );
    }
}

export default Setup;