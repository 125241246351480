import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter, Redirect } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { store } from '../../../Redux/store';
import actions from 'Redux/actions'
import ButtonLoader from 'Components/Loader/ButtonLoader'


class Register extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      email: '',
      phone_number: '',
      responseReceived: false,
      loading: false,
      errors: {},
      exists: false,
      button_active: false
    }

    this.register = this.register.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.validator = new SimpleReactValidator();
  }

  register() {
    const component = this
    const phone = this.state.phone_number.replace(" ", "")

    this.setState({ loading: true })

    axios.post(config.API_REGISTER_URL, {
      email: this.state.email,
      phone_number: phone
    })
      .then(function (response) {
        if (response.status === 201) {
          store.dispatch(actions.savePhoneNumber(phone))
          component.setState({ responseReceived: true, exists: true, loading: false })
        }
        else
          component.setState({ responseReceived: true, loading: false })
      })
      .catch(function (error) {
        console.log(error)
        if (error.response.data.errors) {

          const translated_errors = error.response.data.errors
          if (translated_errors.phone_number.startsWith('[HTTP ')) // Twilio error
            if (translated_errors.phone_number.includes("not a valid"))
              translated_errors.phone_number = 'This phone number seems to be invalid'
            else
              translated_errors.phone_number = 'We couldn’t send the text message. Please contact us.'

          component.setState({ errors: translated_errors, loading: false })
        }
      });
  }

  update(number, country) {
    this.setState({
      ...this.state,
      phone_number: number,
      errors: ''
    });

    if (number.length > 11)
      this.setState({
        button_active: true
      });
    else
      this.setState({
        button_active: false
      });
  }

  onFocus() {
    this.setState({ errors: '' });
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state)
      this.setState({ errors: { ...this.state.errors, phone_number: state.error } })
  }

  submit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.register();
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors, exists, loading } = this.state
    const inputProps = {
      onFocus: this.onFocus
    };
    this.validator.message('phone_number', this.state.phone_number, 'required|min:9')

    if (exists)
      return (<Redirect to="/login" />)

    return (
      <div>
        {responseReceived === false &&
          <form onSubmit={this.submit}>
            <h1>Welcome to WOW</h1>

            <p className="prompt">Enter your phone number to continue.</p>

            <div className="form-group signup-error">
              <div className={errors.phone_number ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.phone_number}</div>
            </div>

            <IntlTelInput
              containerClassName="intl-tel-input form-group signup"
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                this.update(number, countryData);
              }}
              onSelectFlag={(status, value, countryData, number, id) => {
                this.update(number, countryData);
              }}
              inputClassName={"form-control " + (errors.phone_number && 'is-invalid')}
              fieldName="phone_number"
              fieldId="phone"
              formatOnInit="false"
              inputProps={inputProps}
            />
            <button className="btn-primary btn" disabled={!this.state.button_active || loading}>
            {
              loading
                ? <ButtonLoader />
                : <span>Continue</span>
            }
            </button>

            
          </form>}
        {
          responseReceived && <span>Please check your mobile phone and enter the url which is sent to you.</span>
        }
      </div>
    );
  }
}

export default withRouter(Register);