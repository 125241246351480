import React from 'react'
import { Link } from 'react-router-dom'
import './index.scss'
//import { Auth } from 'Services/Auth'

class Settings extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        return  <div>
               
                    <div className="settings-container">
                        <h1>Settings</h1>

                        <ul className="settings-ul">

                            <li><Link to="/profile" className="profile">Profile</Link></li>
                           {/* <li><Link to="/orders" className="history">Order History</Link></li> */}
                            <li><Link to="/page/privacy" className="privacy">Privacy Policy</Link></li>
                            <li><Link to="/page/terms-and-conditions" className="terms">Terms &amp; Conditions</Link></li>
                        </ul>

                        <Link to="/logout" className="logout">Log out</Link>
                    </div>
               
            </div>

                        }
                    }
                    
export default Settings;