import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Main from 'Components/Main';
import {BrowserRouter as Router} from 'react-router-dom';
 
import * as serviceWorker from './serviceWorker';
import {store, persistor} from 'Redux/store'

import 'bootstrap/dist/css/bootstrap.css';

import { PersistGate } from 'redux-persist/integration/react'
import {StripeProvider} from 'react-stripe-elements';
import config from 'config';



ReactDOM.render(

  <StripeProvider apiKey={config.STRIPE_PUBLIC_KEY}>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
       <Main />
      </Router> 
    </PersistGate>
  </Provider>
  </StripeProvider>,

  document.getElementById('root')
);

serviceWorker.unregister();
