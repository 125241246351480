import React from 'react'
import axios from 'axios'
import config from 'config';
import { store } from 'Redux/store';
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import Loader from 'Components/Loader'

import { Auth } from 'Services/Auth'
import './index.scss'

class SingleOrder extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            data: {},
        }

        this.loadOrder = this.loadOrder.bind(this)

    }

    componentWillMount() {
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate()
        })

        this.loadOrder()
    }

    loadOrder() {
        
        let component = this
        axios.get(config.API_ORDER_URL + this.props.match.params.id, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({ 
                    data: response.data.order,
                   })
            });
    }

    render() {

        const { data } = this.state
        const shipping = data.shipping_address

        return (
          

                <div>

                    <div className="order-container single-order">
                    <h1> 
                        
                    <Link to="/orders" className="back"> </Link>
                        
                        Order history</h1>
                        {!data.id && <Loader />}
                        {data && data.id && <div>
                            {
                                data.line_items.map((item , i) => (
                                    <div key={i}>

                                        <div className="img">{data.image && <img src={data.image.src} alt="" />}</div>
                                        <div className="details">
                                        <h1>{item.title}</h1>
                                        <span className="price">${item.price}</span>
                                        <ul>
                                            <li>Quantity: {item.quantity}</li>
                                            { item.variant_title && <li>Options: {item.variant_title}</li>}
                                        </ul>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                ) )
                            }

                            <ul className="details-ul">
                                <li className="line"><div className="desc">Order Number</div> <div className="val">{data.name}</div><div className="clearfix"></div></li>
                                <li><div className="desc">Order Date</div> <div className="val"><Moment format="YYYY/MM/DD" date={data.created_at} /></div><div className="clearfix"></div></li>
                            </ul>

                            <div className="thick-hr"></div>


                            <ul className="details-ul">
                                
                                { shipping &&
                                <li><div className="desc">Shipping Address</div> <div className="val"> 
                                    <span>
                                        {shipping.first_name}{' '}
                                        {shipping.last_name}<br />
                                        {shipping.address1}<br />
                                        {shipping.city}<br />
                                        {shipping.state}, 
                                        {shipping.country}<br />
                                        {shipping.zip}
                                    </span></div><div className="clearfix"></div></li>
                                }
                                { /*<li><div className="desc">Payment Method</div> <div className="val"> {data.name}</div><div className="clearfix"></div></li>*/ }
                            </ul>

                            <div className="thick-hr"></div>

                            <ul className="details-ul subtotal">
                                <li><div className="desc">Subtotal</div> <div className="val light">${data.subtotal_price}</div><div className="clearfix"></div></li>
                                <li><div className="desc light">Shipping</div> <div className="val light">free</div><div className="clearfix"></div></li>
                                <li><div className="desc dark">Total</div> <div className="val"> ${data.total_price}</div><div className="clearfix"></div></li>
                            </ul>

                            <div className="thick-hr"></div>

                        </div> }

                    </div>

                </div>



        )
    }
}

export default SingleOrder;