import React from 'react'
import axios from 'axios'
import config from 'config';

import { Redirect } from 'react-router-dom'

class Verify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responseReceived: false,
            error: false,
        }
    }

    componentDidMount() {
        const { token } = (this.props.match.params)
        const component = this

        axios.post(config.API_VERIFYTOKEN_URL, {
            token: token
        })
            .then(function (response) {
                const { verify } = response.data

                if (verify === true) {
                    component.setState({ error: false, responseReceived: true })
                }
                else {
                    component.setState({ error: "Wrong token", responseReceived: true })
                }

            })
            .catch(function (error) {
                component.setState({ error: error.response.data.error })
            });
    }

    render() {
        const { responseReceived } = this.state
        const { token } = (this.props.match.params)


        if (this.state.error)
            return (
                <Redirect to={{
                    pathname: '/forgotten',
                    state: { error: this.state.error }
                }}
                />
            );
        else
            return (
                responseReceived && <Redirect to={'/reset/' + token} />
            );
    }
}

export default Verify;