import { store } from '../Redux/store';

export const Order = {
  create,
  createAppPay
}

function create(variant, shipping, payment_intent_id)
{
  const first_name = shipping.name.split(" ")[0]
  const last_name = shipping.name.split(' ').slice(1).join(' ');

  let order = 
  {
    order: {
      payment_intent_id: payment_intent_id,

      shipping_address: {
        first_name: first_name,
        last_name: last_name,
        address1: shipping.address1,
        city: shipping.city,
        province: shipping.province,
        country: shipping.country,
        zip: shipping.zip
      },
      line_items: [
        {
          variant_id: variant.id,
          quantity: 1
        }
      ],

    }
  }
  return order
}

function createAppPay(variant, shipping, payment_intent_id) {
  const { auth } = store.getState()

  const first_name = shipping.recipient.split(" ")[0]
  const last_name = shipping.recipient.split(' ').slice(1).join(' ');

  let order = 
  {
    order: {
      payment_intent_id: payment_intent_id,
      email: auth.user.email,
      phone: auth.user.phone_number,
      
      shipping_address: {
        first_name: first_name,
        last_name: last_name,
        address1: shipping.addressLine.join(' '),
        city: shipping.city,
        province: shipping.state,
        country: shipping.country,
        zip: shipping.postalCode,
        phone: shipping.phone,
      },
      line_items: [
        {
          variant_id: variant.id,
          quantity: 1
        }
      ],

    }
  }
  return order
}
