import React from 'react';
import actions from 'Redux/actions';
import { store } from 'Redux/store';
import axios from 'axios';
import Product from './Product';
import Loader from 'Components/Loader'
import { Link, withRouter } from 'react-router-dom'
import { Auth } from 'Services/Auth'

import config from 'config';

class ProductList extends React.Component {

  constructor(props) {
    super(props)


    const { type } = this.props

    this.state = this.getInitialState(type)
    this.currentScroll = 0;

  }

  getInitialState(type) {
    if (type) {
      return {
        category: this.getCategory(type.params.category),
        bookmark: type.path === "/bookmarked" ? true : false,
        search: type.params.phrase,
        scrollPosition: 0,
      }
    }
    else {
      return {
        category: false,
        bookmark: false,
        search: false,
      }
    }
  }

  componentDidUpdate(prevProps) {


    if (this.props.match.params.category !== prevProps.match.params.category) {
      this.setState(this.getInitialState(this.props.type), function () { this.loadProducts(true) })
      store.dispatch(actions.cleanProducts())
    }

    if (this.props.type && this.props.type.params.phrase !== this.state.search) {
      this.setState({ search: this.props.type.params.phrase }, function () { this.loadProducts(true) })
    }
  }

  componentWillMount() {
    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });

    const { products, settings } = store.getState()
    let feedType = 'main'

    if (this.state.category) {
      feedType = 'category' + this.state.category.id
    }

    if (this.state.bookmark) {
      feedType = 'bookmark'
    }

    if (this.state.search) {
      feedType = 'search'
    }

    /* Load products only when moved between the lists
    - if going back to home feed from other pages, no need to load products again */

    if ((!products.data.length) || (settings.feedType !== feedType || feedType === "bookmark")) {
      store.dispatch(actions.cleanProducts())
      this.loadProducts(true)
    }

    this.loadPopular()
    this.loadBookmarks()

    store.dispatch(actions.saveFeedType(feedType))

    document.removeEventListener('scroll', this.trackScrolling)
  }

  componentWillUnmount() {
    this.unsubscribe()
    document.removeEventListener('scroll', this.trackScrolling)
    store.dispatch(actions.saveScrollPosition(this.currentScroll))
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling)
  }


  getCategory(name) {
    const { categories } = store.getState()
    if (!categories.data) return false;


    if (categories.data && categories.data.length) {

      var result = categories.data.filter(obj => {
        return obj.handle === name
      })
    }
    else
      return false;

    return result[0] ? result[0] : false
  }

  loadBookmarks() {
    if (Auth.getToken()) {
      axios.post(config.API_BOOKMARK_URL, { id_product: 0 }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
        .then(function (response) {
          store.dispatch(actions.setBookmarks(response.data));
        });
    }
  }

  loadPopular() {
    axios.get(config.API_URL + "/popular", { headers: { 'Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        store.dispatch(actions.addPopularProducts(response.data));
      })
  }

  loadProducts(initial = false) {

    let queryString = 'products/'


    if (this.state.category) {
      queryString = this.state.category.id + "/"
    }

    if (this.state.bookmark) {
      queryString = "bookmarks/"
    }

    if (this.state.search) {
      queryString = "search/" + this.state.search + "/"
    }


    const { products, order, search } = store.getState()
    store.dispatch(actions.loadingProducts())

    let page = initial ? 0 : parseInt(products.page)

    console.log(search)

    let recently_visited =
      search.visited
        && search.visited[0]
        && search.visited[1]
        && search.visited[0].title
        && search.visited[1].title
        ? [search.visited[0].title, search.visited[1].title]
        : []

    let last_order = order.last.line_items ? order.last.line_items[0].title : ''

    axios.post(config.API_URL + "/" + queryString + "" + (page + 1), {
      last_order: last_order,
      recently_visited: recently_visited
    }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {

        if (initial) {

          store.dispatch(actions.addInitialProducts(response.data));
        }
        else
          store.dispatch(actions.addProducts(response.data));
        store.dispatch(actions.loadedProducts());
      });

  }

  trackScrolling = () => {

    const { products } = store.getState()
    const { lastpage } = products

    const wrappedElement = document.getElementById('list')
    if (this.isBottom(wrappedElement) && !lastpage && !products.loading) {
      this.loadProducts();
    }

    this.currentScroll = window.pageYOffset ? window.pageYOffset : document.documentElement.scrollTop
  }

  render() {

    let { products } = store.getState()

    if (products.data)
      products.data = Object.values(products.data)
    return (
      <div>

        {(products.data.length === 0 && !products.loading && !this.state.bookmark) ?
          <div id="list" className="bookmarks-list text-center"><p><strong>No products found</strong><br />
            <span>Refine your search or choose different category</span></p>
          </div>
          :

          <div id="list" className={(this.state.bookmark ? 'bookmarks-list' : null) + (products.data.length ? '' : ' empty')}>
            {this.state.category && <div className="breadcrumb"><Link to="/categories" className="back"> </Link> <Link to="/categories" className="category">{this.state.category.title}</Link></div>}
            <div className="row">
              <div className="products">
                {products.data && products.data.map((value, index) => {
                  if (value)
                    return <Product className="col-6" key={index} data={value} />
                  else
                    return ''
                })}
              </div>
              {products.loading && <Loader />}
            </div>
          </div>}

        <div className="popular">
          {!products.loading && (this.state.bookmark || this.state.search) && <h2>Popular</h2>}

          <div className="products">
            {!products.loading && (this.state.bookmark || this.state.search) && products.popular && products.popular.products.map((value, index) => {
              return <Product template="simple" className="col-4" key={index} data={value} />
            })}
          </div>

        </div>


      </div>
    )

  }
}

export default withRouter(ProductList);