import React from 'react'
import {store} from 'Redux/store'
import actions from 'Redux/actions';
import axios from 'axios';

import config from 'config';

import './Header.scss'
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import Logo from '../../Svg/Logo'
import Search from './Search'

import { Link } from 'react-router-dom';


class Header extends React.Component
{

  constructor(props)
  {
    super(props)
    this.loadCategories = this.loadCategories.bind(this)

    this.state = {
      showMenu: ''
    }

    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

   componentWillMount()
   {
     let { categories } = store.getState()

     if(categories !== {})
     {
       this.loadCategories()
     }
   }

   loadCategories(initial = false)
   {

     axios.get(config.API_COLLECTIONS_URL)
     .then(function (response) {
        store.dispatch(actions.saveCategories(response.data));
     });

   }

   openMenu()
   {
     this.setState({showMenu: 'show'})
   }

   closeMenu()
   {
     this.setState({showMenu: ''})
   }

   toggleMenu()
   {
     this.state.showMenu
      ? this.closeMenu()
      : this.openMenu()
   }

   render()
   {
    const {auth, categories} = store.getState();
    
    
    return (

<div className="navbar-container">

<Navbar bg="bg-blue" expand="false" className="header">

  <Link className="navbar-brand" to="/">
      <Logo />
  </Link>


  <button aria-controls="basic-navbar-nav" type="button" aria-label="Toggle navigation" className={"navbar-toggler " + (this.state.showMenu ? 'collapsed' : '')} onClick={this.toggleMenu}><span className="navbar-toggler-icon"></span></button>


  <div><Search /></div>

  <div className={"navbar-collapse collapse" + this.state.showMenu} id="basic-navbar-nav">

    <Nav className="mr-auto">
      <div className="header-overlay" onClick={this.closeMenu}></div>
      <div className="nav-bg" onClick={this.closeMenu}></div>

      <NavDropdown title="Shop Categories" id="basic-nav-dropdown">

      {categories.data && categories.data.length && categories.data.map((value, index) => {
           return <Link onClick={this.closeMenu} className="dropdown-item" key={index} to={ "/category/" + value.handle}> {value.title} </Link>
          })}

      </NavDropdown>

      <Link onClick={this.closeMenu} className="nav-link" to="/profile">Edit Profile</Link>
      {/*<Nav.Link href="#home">Manage Adresses</Nav.Link>
      <Nav.Link href="#home">Payment</Nav.Link>*/}
      <Link onClick={this.closeMenu} className="nav-link" to="/orders">Order History</Link>

      <Link onClick={this.closeMenu} className="nav-link" to="/articles/support">Support</Link>

      { auth.auth && <Link onClick={this.closeMenu} className="nav-link" to="/logout">Log out</Link> }
     
    </Nav>

  </div>
</Navbar>

</div>

    );
   }
}

export default Header;