import React from 'react'
import './Overlay.scss'
import Register from './Register/'
import Setup from 'Components/Overlay/Setup'
import Login from 'Components/Overlay/Login'
import Forgotten from 'Components/Overlay/Forgotten'
import Reset from 'Components/Overlay/Reset'
//import { store } from '../../Redux/store';
//import actions from 'Redux/actions'

class Overlay extends React.Component
{

    componentDidMount() {
     
      document.body.classList.add('overlay-open');
      //store.dispatch(actions.setUser({}));
    }


    render()
    {
      return (
           <div className="overlay">
              <div className="xycenter">
                  {this.props.type === "register" && <Register />}
                  {this.props.type === "setup" && <Setup />}
                  {this.props.type === "login" && <Login />}
                  {this.props.type === "forgotten" && <Forgotten />}
                  {this.props.type === "reset" && <Reset />}
              </div>
           </div>
      );
    }
}

export default Overlay;