import React from 'react';
import actions from 'Redux/actions';
import { store } from 'Redux/store';
import axios from 'axios';
import Order from './Order';
import { Link } from 'react-router-dom';

import Product from 'Components/Product/Product'
import Loader from 'Components/Loader'

import { Auth } from 'Services/Auth'

import config from 'config';
import './index.scss'

class OrderList extends React.Component {

  /* constructor(props) {
     super(props)
   }*/

  componentWillMount() {
    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });

    this.loadOrders()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  loadOrders() {
    axios.get(config.API_ORDERS_URL, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        store.dispatch(actions.addOrders(response.data))
      });
  }

  render() {

    let { order, products } = store.getState()

    if (order.data)
      order.data = Object.values(order.data)

    return (


      <div>

        <div className="order-container">
          <h1>Order history</h1>

          <div id="list">

            <div>
              {!order.data && <Loader />}
              {order.data && order.data.length === 0 &&
                <div className="no-orders"><p><strong>You made no orders yet!</strong><br />
                  <span>All order details and tracking goes here</span></p>
                  <div><Link className="button" to="/">Continue Shopping</Link></div>

                  <div className="popular">

                    <div className="products">
                      {products.popular && products.popular.products.map((value, index) => {
                        return <Product template="simple" className="col-4" key={index} data={value} />
                      })}
                    </div>
                  </div>
                </div>
              }

              <div className="orders">
                {order.data && order.data.map((value, index) => {
                  return <Order className="col-12" key={index} data={value} />
                })}

              </div>

            </div>
          </div>
        </div>
      </div>

    )

  }
}

export default OrderList;