
import React from 'react';
import './index.scss'

class ButtonLoader extends React.Component {
    render() {
        return (
            <div className="bloader">
                <div claclassNamess="bline"></div>
                <div className="bline"></div>
                <div className="bline"></div>
                <div className="bline"></div>
            </div>
        )

    }
}

export default ButtonLoader;
