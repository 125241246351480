import React from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'

import axios from 'axios'
import { Auth } from 'Services/Auth'
import { Order } from 'Services/Order'
import config from 'config';

import actions from 'Redux/actions';
import { store } from 'Redux/store';

import Loader from 'Components/Loader'

import './index.scss'
import SimpleReactValidator from 'simple-react-validator'

class Card extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCardPayment = this.handleCardPayment.bind(this)
    this.handleReady = this.handleReady.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      product: this.props.product,
      shipping: {
        name:'',
        address1:'',
        city:'',
        country_code: '',
        province: '',
        zip: '',
      },
      billing: {},
      client_secret: {},
      errorStripe: '',
      error: {},
      payment_intent: {},

    }

    this.validator = new SimpleReactValidator();    

  }

  handleChange(e)
  {
    
    this.setState({
      shipping: {
        ...this.state.shipping, 
        [e.target.name]: e.target.value, },
    });
  }

  componentWillMount()
  {
    const component = this

    axios.get(config.API_GET_ADDRESSES_URL, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
    .then(function (response) {

      if(response.data.addresses && response.data.addresses.length)
       component.setState({shipping:response.data.addresses[0]})
    });
  }

  handleReady = (element) => {
    this.setState({ cardElement: element })
  }

  handleCardPayment(clientSecret) {
    const component = this
    this.props.stripe.handleCardPayment(
      clientSecret, this.state.cardElement, {
        payment_method_data: {

        }
      }
    ).then(function (result) {
      if (result.error) {
        
        component.setState({
          paymentSending: false,
          succeeded: false,
          errorStripe: result.error,
          details: {},
        })
      } else {

        if (result.paymentIntent.status === "succeeded") {

          axios.post(config.API_CREATE_ORDER_URL,
            Order.create(component.props.variant, component.state.shipping, component.props.payment_intent.id),
            { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {

              if (response.data.order.id) {

                component.setState({
                  paymentSending: false,
                  succeeded: true,
                  error: {},
                  details: result,
                })

                store.dispatch(actions.saveLastOrder(response.data.order))
                store.dispatch(actions.displayThankYou())
              }

            })
            .catch(function(error)
            {
              console.log(error)
              component.setState({
                paymentSending: false,
              })
            })
        }
        else if(result.paymentIntent.status === "requires_source")
        {
          component.handleCardPayment(this.state.client_secret)
        }

      }
    })
  }

  handleSubmit = (ev) => {
    console.log(this.state.shipping)
    ev.preventDefault()
    if (this.validator.allValid()) {
      this.setState({ paymentSending: true })
     
      const component = this
      component.handleCardPayment(this.props.payment_intent.client_secret)

    }
    else
    {
      
      this.setState({error : this.validator.getErrorMessages() }, function() { console.log(this.state.error) } );
      this.forceUpdate();
    }

   
  }

  render() {

    const { error, errorStripe, shipping, paymentSending } = this.state;

    this.validator.message('name', shipping.name, 'required|string|min:2')
    this.validator.message('address1', shipping.address1, 'required|string|min:5')
    this.validator.message('city', shipping.city, 'required|string|min:2')
    this.validator.message('province', shipping.province, 'required|string|min:2')
    this.validator.message('zip', shipping.zip, 'required|string|min:4')


      return (
        <form className="payform" onSubmit={this.handleSubmit}>
          <section>
            {/*  <h2>Shipping &amp; Billing Information</h2>*/ }
            <fieldset className="with-state">
              <label className={(error.name ? ' invalid' : '')}>
                <span>Shipping address</span>
                <input value={shipping.name} onChange={this.handleChange} name="name" className="field" placeholder="Jenny Rosen" required="" />
              </label>
             {/*} <label>
                <input defaultValue={auth.user.email} disabled={true} name="email" type="email" className="field" placeholder="Email" required="" />
                 </label>*/}
              <label className={(error.address1 ? ' invalid' : '')}>
                <input value={shipping.address1} onChange={this.handleChange} name="address1" className="field" placeholder="Shipping Address" />
              </label>
              <label className={(error.city ? ' invalid' : '')}>
                <input value={shipping.city} onChange={this.handleChange} name="city" className="field" placeholder="City" />
              </label>
              <label className={"state " + (error.province ? ' invalid' : '')}>
                <input value={shipping.province} onChange={this.handleChange} name="province" className="field" placeholder="State" />
              </label>
              <label className={"zip " + (error.zip ? ' invalid' : '')}>
                <input value={shipping.zip} onChange={this.handleChange} name="zip" className="field" placeholder="ZIP Code" />
              </label>
              <label className="select">
                <div id="country" className="field US">

                  <select value={shipping.country_code} onChange={this.handleChange} name="country">
                    <option disabled>Country</option>
                   {/*} <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="BE">Belgium</option>
                    <option value="BR">Brazil</option>
                    <option value="CA">Canada</option>
                    <option value="CN">China</option>
                    <option value="DK">Denmark</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                    <option value="HK">Hong Kong</option>
                    <option value="IE">Ireland</option>
                    <option value="IT">Italy</option>
                    <option value="JP">Japan</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MX">Mexico</option>
                    <option value="NL">Netherlands</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NO">Norway</option>
                    <option value="PT">Portugal</option>
                    <option value="SG">Singapore</option>
                    <option value="ES">Spain</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
      <option value="GB">United Kingdom</option>*/}
                    <option value="US">United States</option>
                  </select>
                </div>
              </label>

              <CardElement hidePostalCode={true} onReady={this.handleReady} />

              { paymentSending ? <Loader /> : <button>Pay</button>}
              {errorStripe.message && <div className="">{errorStripe.message}</div>}
            </fieldset>

          </section>
        </form>

      )
  }

}

export default injectStripe(Card);