import { combineReducers } from 'redux';
import  productReducer  from 'Redux/Reducers/ProductReducer'
import  authReducer  from 'Redux/Reducers/AuthReducer'
import  bookmarkReducer  from 'Redux/Reducers/BookmarkReducer'
import  categoryReducer  from 'Redux/Reducers/CategoryReducer'
import  searchReducer  from 'Redux/Reducers/SearchReducer'
import paymentReducer from 'Redux/Reducers/PaymentReducer'
import orderReducer from 'Redux/Reducers/OrderReducer'
import settingsReducer from 'Redux/Reducers/SettingsReducer'

const appReducer = combineReducers({
  products: productReducer,
  auth: authReducer,
  bookmarks: bookmarkReducer,
  categories: categoryReducer,
  search: searchReducer,
  payment: paymentReducer,
  order: orderReducer,
  settings: settingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    document.body.classList.add("hidden")
    state = undefined;
    localStorage.removeItem('persist:root')

  }

  return appReducer(state, action);
};

export default rootReducer;