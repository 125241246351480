import React from 'react';
import { Link } from 'react-router-dom'
import './index.scss'

class Order extends React.Component
{
    render()
    {
        const { data, className } = this.props;
        const item = data.line_items[0]
      
        return (
            <div className={"order " + className}>
              <Link to={"/order/" + data.id}>
              <div className="img-container">
                <div className="img">{data.image && <img src={data.image.src} alt="" />}</div>
            </div>
              <div className="details">
              <h1>{item.title}</h1>
              <ul>
                  <li>${item.price}</li>
                  <li>Quantity: ${item.quantity}</li>
                  { item.variant_title && <li>Options: {item.variant_title}</li>}
              </ul>
              </div>
              

              <div className="clearfix"></div>
              </Link>
            </div>
        )
       } 
    
}

export default Order;