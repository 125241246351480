import React from 'react'
import axios from 'axios'
import config from 'config';

import { Auth } from 'Services/Auth'
import { Redirect } from 'react-router-dom'
import { store } from 'Redux/store'

import actions from 'Redux/actions';

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseReceived: false,
      error: false,
    }
  }

  componentDidMount() {
    const { userId, code } = (this.props.match.params)
    const component = this

    axios.post(config.API_VERIFY_URL, {
      user_id: userId,
      verification_code: code,
    })
      .then(function (response) {
        component.setState({ responseReceived: true })

          const { access_token } = response.data
          Auth.setToken(access_token)
          store.dispatch(actions.authenticate(access_token))
          store.dispatch(actions.setUser({id: userId}))
        
      })
      .catch(function(error){ component.setState({ error: error.response.data.error }) });
  }

  render() {
    const { responseReceived } = this.state

    console.log(this.state)
    if (this.state.error)
      return (
         <Redirect to={{
          pathname: '/register',
          state: { error: this.state.error }
      }}
/>
      );
    else
      return (
        responseReceived && <Redirect to='/' />
      );
  }
}

export default Verify;