import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter, Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
import { store } from '../../../Redux/store';
import actions from 'Redux/actions'
import Loader from 'Components/Loader'

class Reset extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      password: '',
      responseReceived: false,
      errors: {},
      button_active: false,
      loading: false
    }

    this.updatePassword = this.updatePassword.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  componentWillMount()
  {
    store.dispatch(actions.logout())
  }

  updatePassword() {
    const component = this
    const { token } = (this.props.match.params)
   
    axios.post(config.API_RESET_URL, {
      password: this.state.password,
      token: token
    })
      .then(function (response) {
        component.setState({responseReceived: true})
        if(response.data.user)
        {
          store.dispatch(actions.authenticate(response.data.access_token))
          store.dispatch(actions.setUser(response.data.user))
          this.setState({ loading: false });
        }
      })
      .catch(function (error) {
        component.setState({error: error.response.data.error, loading: false})
       
      });
  }

  update(e)
  {
     const { name, value } = e.target;

      this.setState({
        ...this.state,
        [name]: value,
      });

      if (value.length>5)
      this.setState({
        button_active: true
      });
    else
      this.setState({
        button_active: false
      });
  }

  submit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    if (this.validator.allValid()) {
      this.updatePassword();
    } else {
      this.setState({ errors: this.validator.getErrorMessages(), loading: false });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors } = this.state
    this.validator.message('password', this.state.password, 'required|min:6')

    return (
      <div>
        {responseReceived === false &&
          <form onSubmit={this.submit} className="new-password">
            <h1>New password</h1>
            <p className="prompt">Enter your new password.</p>
            <div className="form-group">
              <input className={"form-control " + (errors.email && 'is-invalid')} id="password" type="password" name="password" placeholder="Password" onChange={this.update} />
              <div className="invalid-feedback">{errors.email}</div>
              <div className={errors.password ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.password}</div>
            </div>


            { this.state.button_active ?
            <button className={this.state.loading ? "btn-primary btn loading" : "btn-primary btn"}>
              {this.state.loading ? <Loader /> : "Continue" }</button> :
            <button className="btn-primary btn" disabled="disabled">Continue</button>
            }

          </form>}
        {
          responseReceived && <span>Your password updated successfully. <Link to="/">Go to homepage</Link></span>
        }
      </div>
    );
  }
}

export default withRouter(Reset);