import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { store } from 'Redux/store';
import { Auth } from 'Services/Auth'
import { Link } from 'react-router-dom'
import './../../index.scss'

//import actions from 'Redux/actions'

class PhoneUpdate extends React.Component {
  constructor(props) {
    super(props)

    const { auth } = store.getState()

    this.state = {
      email: auth.user.email,
      phone_number: '',
      responseReceived: false,
      errors: {},
      exists: false,
    }

    this.sendResetLink = this.sendResetLink.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  sendResetLink() {
    const component = this

    const { auth } = store.getState()
    const phone_number = auth.user.phone_number.replace(" ", "")
    const new_phone_number = this.state.phone_number.replace(" ", "")

    axios.post(config.API_PHONE_UPDATE_URL, {
      phone_number: phone_number,
      new_phone_number: new_phone_number,
    }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        console.log(response)
        component.setState({ responseReceived: true })
      })
      .catch(function (error) {
        console.log(error.response.data.errors)
       // component.setState({ errors: error.response.data.errors });
      });
  }

  update(number, country) {
    this.setState({
      ...this.state,
      phone_number: number,
      errors: {}
    });
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state)
      this.setState({ errors: { ...this.state.errors, phone_number: state.error } })
  }

  submit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      if (store.getState().auth.user.phone_number === this.state.phone_number) {
        this.setState({ errors: { phone_number: 'Phone number provided is the same as currently used.' } });
        this.forceUpdate();
      }
      else
        this.sendResetLink();
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors } = this.state
    this.validator.message('phone_number', this.state.phone_number, 'required|min:9')
    console.log(this.state)
    return (
      <div>
      
        {responseReceived === false &&
          <form onSubmit={this.submit} className="profile-edit phone-edit">

            <h1 className="h1-header">
            <Link to="/profile" className="back"> </Link>
            Phone Number</h1>

            <div className="forgotten">

              <div className="form-group signup-error">
                <div className={errors.phone_number ? ' visible' : 'invalid-feedback'}>{errors.phone_number}</div>
              </div>

              <div className="form-group phone-edit-group">
                
              <IntlTelInput
              containerClassName="intl-tel-input form-group signup"
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                this.update(number, countryData);
              }}
              onSelectFlag={(status, value, countryData, number, id) => {
                this.update(number, countryData);
              }}
              inputClassName={"form-control " + (errors.phone_number && 'is-invalid')}
              fieldName="phone_number"
              fieldId="phone"
              formatOnInit="false"
             />

              </div>

              <button className="btn-primary btn">
                Next
               </button>
            </div>
          </form>}
        {
          responseReceived && <div className="product-container"><span>Enter the confirmation code we sent to {this.state.phone_number}.
          If you didn’t get it, we can resend the code.</span></div>
        }

      </div>
    );
  }
}

export default withRouter(PhoneUpdate);