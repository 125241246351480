import React from 'react';
import './Product.scss'
import Bookmark from 'Components/Bookmark'
import {Link} from 'react-router-dom'

class Product extends React.Component
{
    randomizeImage()
    {
        // Randomizing has to be coded yet
        return this.props.data.default_image.src.replace(".jpg","_medium.jpg").replace(".png","_medium.png")
    }

    render()
    {
        
        const {data, className, template} = this.props;
        
        if(template !== "simple") {
        return (
            <div className={"product " + className}>
            { data &&
            <div>
                <Link  to={ "/product/" + data.id}>
               {
                data.default_variant &&
                data.default_variant.compare_at_price ? <div className="discount">-{Math.round(data.default_variant.discount)}%</div> : null }
            
               <div className="img">
               { data.default_image  && <img alt="product" src={this.randomizeImage()} width="100%" /> }
               </div>

               <div className="prices">
                <span className="price"><span className="currency">$</span>{data.default_variant && data.default_variant.price}</span>
                { data.default_variant && data.default_variant.compare_at_price && <span className="stroke">${data.default_variant.compare_at_price}
                </span> }<br/>
               { data.order_count > 0 
                    && <span className="bought">{data.order_count}&nbsp;bought&nbsp;this</span> }
               </div>
               </Link>
           

              <Bookmark id_product={data.id} />
              </div>
            }
               <div className="clearfix"></div>
            </div>

        ) }
       else
       {
        return (
            <div className={"product " + className}>
            { data &&
                <Link  to={ "/product/" + data.id}>

            <div className="img">
               { data.default_image && <img alt="product" src={data.default_image.src} width="100%" /> }
            </div>

               </Link>
            }
            <div className="clearfix"></div>
            </div>

        )
       } 
    }
}

export default Product;