import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
import { store } from 'Redux/store';
import { Auth } from 'Services/Auth'
import { Link } from 'react-router-dom'
import './../../index.scss'

//import actions from 'Redux/actions'

class EmailUpdate extends React.Component {
  constructor(props) {
    super(props)

    const { auth } = store.getState()

    this.state = {
      email: auth.user.email,
      phone_number: '',
      responseReceived: false,
      errors: {},
      exists: false,
    }

    this.sendResetLink = this.sendResetLink.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  sendResetLink() {
    const component = this

    const { auth } = store.getState()
    console.log(auth)

    axios.post(config.API_EMAIL_UPDATE_URL, {
      email: auth.user.email,
      new_email: this.state.email,
    }, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        console.log(response)
        component.setState({ responseReceived: true })
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  update(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state)
      this.setState({ errors: { ...this.state.errors, phone_number: state.error } })
  }

  submit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      if (store.getState().auth.user.email === this.state.email) {
        this.setState({ errors: { email: 'E-mail provided is the same as currently used.' } });
        this.forceUpdate();
      }
      else
        this.sendResetLink();
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors } = this.state
    this.validator.message('email', this.state.email, 'required|email')

    return (
      <div>
     
        {responseReceived === false &&
          <form onSubmit={this.submit} className="profile-edit">
            <h1 className="h1-header">
            <Link to="/profile" className="back"> </Link>
              Email</h1>

            <div className="forgotten">

              <div className="form-group signup-error">
                <div className={errors.email ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.email}</div>
              </div>

              <div className="form-group">
                <input className={"form-control " + (errors.email && 'is-invalid')} id="email" type="text" name="email" defaultValue={this.state.email} placeholder="Email" onChange={this.update} />
                <div className="invalid-feedback">{errors.email}</div>
              </div>

              <button className="btn-primary btn">
                Next
               </button>
            </div>
          </form>}
        {
          responseReceived && 
          <div className="settings-container">
          <div className="row settings-header">
            <div className="col-3"></div>
            <div className="col-6"><h1>Confirmation</h1></div>
            <div className="col-3"><Link to="/profile" className="continue">Continue</Link></div>  
          </div>
          
          <div className="product-container text">We sent an email to <span>{this.state.email}</span> with a confirmation link. Please click on it to confirm the new email address.</div>
          </div>
        }
      
      </div>
    );
  }
}

export default withRouter(EmailUpdate);