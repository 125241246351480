import React from 'react';
import App from 'Components/App';
import { Route, withRouter } from 'react-router-dom'
import Verify from 'Components/Verify'
import VerifyEmail from 'Components/VerifyEmail'
import VerifyToken from 'Components/VerifyToken'
import Thankyou from 'Components/Thankyou'
import Logout from 'Components/Logout'

import SingleProduct from 'Components/Product/SingleProduct'
import SingleOrder from 'Components/Order/SingleOrder'
import OrderList from 'Components/Order/OrderList'
import Settings from 'Components/Settings'
import Page from 'Components/Page'
import Article from 'Components/Page/Article'
import PageList from 'Components/Page/PageList'
import Profile from 'Components/Settings/Profile'
import EmailUpdate from 'Components/Settings/Profile/EmailUpdate'
import PhoneUpdate from 'Components/Settings/Profile/PhoneUpdate'
import VerifyUpdateEmail from 'Components/Settings/Profile/EmailUpdate/verify'
import VerifyUpdatePhone from 'Components/Settings/Profile/PhoneUpdate/verify'

import Categories from 'Components/Category'

import axios from 'axios'
import { Auth } from 'Services/Auth'

import actions from 'Redux/actions';
import { store } from 'Redux/store';

import Header from 'Components/App/Header'
import Footer from 'Components/App/Footer'

class Main extends React.Component {

    constructor(props)
    {
        super(props)
        this.state = {
            showFooter: !this.isProduct(window.location.pathname),
        }
    }

    componentWillMount()
    {
        axios.interceptors.response.use(null, function(error) {
            if (error && error.response && error.response.status === 401) {
              Auth.logout();
              window.location.href="/"
            }
            
            return Promise.reject(error);
          });
          
          this.props.history.listen((location, action) => {
            this.setState({showFooter: !this.isProduct(location.pathname)})
            store.dispatch(actions.closeSearch())
   
        }); 

        if (window.performance) {
            if (performance.navigation.type === 1) {
              store.dispatch(actions.cleanProducts())

            } else {
              
            }
          }
    }

    isProduct(location)
    {
       return location.startsWith("/product");
    }


    render()
    {
        return (
         <div className="app">
         <Header />   
         
            <Route exact={true} path="/" component={App} />
            <Route path="/verify/:userId/:code" component={Verify} />
            <Route path="/verify-email/:userId/:code" component={VerifyEmail} />
            <Route path="/verifytoken/:token" component={VerifyToken} />
            <Route path="/reset/:token" render={() => ( <App type="reset" /> )} />
            <Route  path="/logout" component={Logout} />
            <Route exact={true} path="/register" render={() => ( <App type="register" /> )} />
            <Route exact={true} path="/login" render={() => ( <App type="login" /> )} />
            <Route exact={true} path="/forgotten" render={() => ( <App type="forgotten" /> )} />
            <Route name='shared'  path="/product/:id" state={"XFG"}  component={SingleProduct} />
            <Route  path="/category/:category" component={App} />
            <Route  path="/search/:phrase" component={App} />
            <Route  path="/bookmarked" component={App} />
            <Route  path="/categories" component={Categories} />
            <Route  path="/thankyou/:id" component={Thankyou} />
            <Route  path="/orders" component={OrderList} />
            <Route  path="/order/:id" component={SingleOrder} />
            <Route  path="/settings" component={Settings} />
            <Route  path="/page/:page" component={Page} />
            <Route  path="/article/:article" component={Article} />
            <Route  path="/articles/:tag" component={PageList} />
            <Route  path="/profile" exact={true} component={Profile} />

            <Route  path="/profile/email" exact={true} component={EmailUpdate} />
            <Route  path="/profile/phone" exact={true} component={PhoneUpdate} />

            <Route path="/profile/email/:userId/:token" component={VerifyUpdateEmail} />
            <Route path="/update/phone/:userId/:token" component={VerifyUpdatePhone} />
            
        {this.state.showFooter && <Footer /> }
        </div>
        )
    }
}

export default withRouter(Main);