import React from 'react'
import {store} from 'Redux/store'
import './Footer.scss'
// import {Navbar, Nav, NavDropdown, Form, FormControl, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'

//import BottomBubble from 'Components/Bubble'

import HomeOff from 'Components/Svg/TabBar/HomeOff'
import SearchOff from 'Components/Svg/TabBar/SearchOff'
import BookmarksOff from 'Components/Svg/TabBar/BookmarksOff'
import CategoriesOff from 'Components/Svg/TabBar/CategoriesOff'
import UserOff from 'Components/Svg/TabBar/UserOff'

import HomeOn from 'Components/Svg/TabBar/HomeOn'
import SearchOn from 'Components/Svg/TabBar/SearchOn'
import BookmarksOn from 'Components/Svg/TabBar/BookmarksOn'
import CategoriesOn from 'Components/Svg/TabBar/CategoriesOn'
import UserOn from 'Components/Svg/TabBar/UserOn'


import actions from 'Redux/actions'

class Footer extends React.Component {
    

    toggleSearch() {
        const { search } = store.getState();
        console.log(search)
    
        if (search.open) {
          store.dispatch(actions.closeSearch())
        }
        else
          store.dispatch(actions.openSearch())
      }

    render() {
        const { search } = store.getState();
        const path = window.location.pathname

        return (

        <div className="footer">
                <ul>
                    <li><Link to="/">{(path === "/") ? <HomeOn /> : <HomeOff />}</Link></li>
                    <li><Link to="/categories">{(path === "/categories") ? <CategoriesOn /> : <CategoriesOff />}</Link></li>
                    <li><button onClick={this.toggleSearch}>{(path.startsWith('/search') || search.open) ? <SearchOn /> : <SearchOff />}</button></li>
                    <li><Link to="/bookmarked">{path === "/bookmarked" ? <BookmarksOn /> : <BookmarksOff />}</Link></li>
                    <li><Link to="/settings">{(path === "/settings" || path.startsWith('/profile')) ? <UserOn /> : <UserOff />}</Link></li>
                </ul>
                <div className="clearfix"></div>
                { /*<BottomBubble />*/}
            </div>
            

        );
    }
}

export default Footer;