import React from 'react';
import axios from 'axios';
import config from 'config';
import { store } from 'Redux/store'
import actions from 'Redux/actions'
import ButtonLoader from 'Components/Loader/ButtonLoader'
import SimpleReactValidator from 'simple-react-validator'

//import './Register.scss'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      loading: false,
      password: '',
      errors: {},
    }

    this.login = this.login.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  login() {
    const component = this
    const { auth } = store.getState()
    this.setState({ loading: true })

    axios.post(config.API_LOGIN_URL, {
      phone_number: auth.phone_number,
      password: this.state.password
    })
      .then(function (response) {

        store.dispatch(actions.authenticate(response.data.access_token))
        store.dispatch(actions.setUser(response.data.user))
        component.setState({ loading: false })

      })
      .catch(function (error) {
        if (error.response.data.errors) {
          component.setState({ errors: error.response.data.errors, loading: false })
        }
      });
  }



  update(e) {
    const { name, value } = e.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  submit(e) {
    e.preventDefault();
    this.validator.message('password', this.state.password, 'required|min:6')

    if (this.validator.allValid()) {
      this.login();
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });

      this.forceUpdate();
    }
  }

  render() {
    const { errors, loading, password } = this.state

    return (
      <div>
        {

          <form onSubmit={this.submit}>
            <h1>Welcome!</h1>
            <p className="prompt">{/*auth.phone_number*/}
              Enter 6-digit number you received.
            </p>

            <div className="form-group signin">
              <input className={"form-control " + (errors.password && 'is-invalid')} id="password" name="password" inputmode="decimal" pattern="[0-9]*" type="tel" placeholder="6-digit number" onChange={this.update} />
              <div className="invalid-feedback">{errors.password}</div>

            </div>

            <button className="btn-primary btn" disabled={loading || password.length !== 6}>
              {
                loading
                  ? <ButtonLoader />
                  : <span>Log In</span>
              }

            </button>


            {/* 
               <div className="or"><span>or</span></div>

               <p className="center"><Link to="/register" className="signup">Sign Up</Link></p>
*/}
          </form>}


      </div>
    );
  }
}

export default Login;