import React from 'react'

class BookmarkOn extends React.Component
{
render()
{
    return (

<svg xmlns="http://www.w3.org/2000/svg" width="17" height="19">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        stroke="#0E0E0E"
        strokeWidth="0.3"
        d="M1 1h15v17l-7.5-6.375L1 18z"
      ></path>
    </svg>

)
}
}

export default BookmarkOn;