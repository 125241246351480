import React from 'react'

import axios from 'axios'
import config from 'config';
import { Link } from 'react-router-dom'
import Loader from 'Components/Loader'

import { Auth } from 'Services/Auth'
import './index.scss'

import Zendesk from "react-zendesk";

const ZENDESK_KEY = "7be793cd-3b3e-4947-abf8-7b0e2dec7bcb"


class PageList extends React.Component {

    constructor(props) {
        super(props);

        this.state = { data: {}, loading: true }
    }

    componentWillMount() {
        const { tag } = this.props.match.params


        let component = this
        axios.get(config.API_PAGES_BY_TAG_URL + '/' + tag, { headers: { 'Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({
                    data: response.data,
                    loading: false,
                })
            });

    }

    componentWillUnmount() {
        this.setZendeskPosition(false)
    }

    setZendeskPosition(visible)
    {

        const x = visible ? '0px' : '-1000px'
        const launcher = document.querySelector("#launcher")

        if (launcher)
            launcher.style.setProperty('right', x, 'important');
    }

    render() {

        const { data, loading } = this.state
        return <div>

            <div className="page-container">
                <div className="breadcrumb">

                    <h1>Customer Support</h1>
                </div>
                {loading && <Loader />}
                <ul className="arrow-links">
                    {data.articles && data.articles.map((value, index) => {
                        return <li><Link to={"/article/" + value.handle}>{value.title}</Link></li>
                    })}
                </ul>



            </div>
            <Zendesk zendeskKey={ZENDESK_KEY} onLoaded={() => this.setZendeskPosition(true)} />
        </div>

    }
}

export default PageList;